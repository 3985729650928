<template>
  <div
    class="vertical-layout h-100"
    :class="[layoutClasses]"
    :data-col="isNavMenuHidden ? '1-column' : null"
  >
    <!-- Navbar -->
    <div
      :class="
        (hasVerticalMenu &&
          !isTablet) || 
          isMobile ? '' : 'allMenu'"
    >
      <b-navbar
        :toggleable="false"
        :variant="isNexos ? 'dark' : navbarBackgroundColor"
        class="header-navbar navbar align-items-center fixed-top navbar-full-width"
        :class="isNexos ? 'navbar-nexos' : 'navbar-shadow'"
        :style="isNexos ? 'background-color: #2b2a28 !important;' : ''"
      >
      <!-- :class="[navbarTypeClass]" -->
        <slot
          name="navbar"
          :toggleVerticalMenuActive="toggleVerticalMenuActive"
          :navbarBackgroundColor="navbarBackgroundColor"
          :navbarTypeClass="[
            ...navbarTypeClass,
            'header-navbar navbar navbar-shadow align-items-center',
          ]"
        >
          <AppNavbarVerticalLayout
            :toggle-vertical-menu-active="toggleVerticalMenuActive"
          />
        </slot>
      </b-navbar>
    </div>
    <!--/ Navbar -->

    <!-- SPACE -->
    <!-- Vertical Nav Menu -->
    <component
      :is="isNexos ? 'VerticalNavMenuNexos' : 'VerticalNavMenu'"
      v-if="
        isNexos || 
        (!hasNotMenu &&
        !isNavMenuHidden &&
        ((appsInMenu && appsInMenu.length > 1) || hasStructure))"
      key="appsin-menu"
      :is-vertical-menu-active="isVerticalMenuActive"
      :toggle-vertical-menu-active="toggleVerticalMenuActive"
      :apps-in-menu="appsInMenu"
    >
      <template #header="slotProps">
        <slot name="vertical-menu-header" v-bind="slotProps" />
      </template>
    </component>

    <!-- BACKOFFICE -->
    <!-- :key="keyForMenus" -->
    <VerticalNavMenu
      v-if="hasBackofficeMenu && isStaff"
      key="backoffice-menu-staff"
      :is-vertical-menu-active="isVerticalMenuActive"
      :toggle-vertical-menu-active="toggleVerticalMenuActive"
      :apps-in-menu="backofficeItems"
      title="Backoffice"
      subtitle="BO"
    >
      <template #header="slotProps">
        <slot name="vertical-menu-header" v-bind="slotProps" />
      </template>
    </VerticalNavMenu>
    
    <!-- MY ACCOUNT -->
    <component
      :is="isNexos ? 'VerticalNavMenuNexos' : 'VerticalNavMenu'"
      v-if="hasMyselfMenu"
      key="myself-menu"
      :is-vertical-menu-active="isVerticalMenuActive"
      :toggle-vertical-menu-active="toggleVerticalMenuActive"
      :apps-in-menu="membersSettingsInMenu"
      :is-backoffice="false"
      :title="$t('user-menu.profile-settings')"
      subtitle="ME"
    >
      <template #header="slotProps">
        <slot name="vertical-menu-header" v-bind="slotProps" />
      </template>
    </component>

    <!-- Vertical Nav Menu Overlay -->
    <div
      class="sidenav-overlay"
      :class="overlayClasses"
      @click="isVerticalMenuActive = false"
    />
    <!-- /Vertical Nav Menu Overlay -->

    <!-- Content -->

    <!-- CONTENT TYPE: Left -->
    <transition :name="routerTransition" mode="out-in">
      <component
        :is="layoutContentRenderer"
        :key="layoutContentRendererKey"
        v-bind="layoutContentRendererProps"
        :class="hasVerticalMenu ? '' : 'noVerticalMenu'"
      >
        <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
          <slot :name="name" v-bind="data" />
        </template>
      </component>
    </transition>
    <!--/ Content -->

    <!-- Footer -->
    <footer
      v-if="hasFooter"
      class="footer footer-fixed"
      :class="
        $route.path.includes('/backoffice') ||
        $route.path.includes('/myself') ||
        (!hasNotMenu && !isNavMenuHidden && appsInMenu && appsInMenu.length > 1)
          ? [footerTypeClass]
          : 'noVerticalMenu'"
    >
      <slot name="footer">
        <AppFooter />
      </slot>
    </footer>
    <!-- /Footer -->

    <slot name="customizer" />
  </div>
</template>

<script>
import { onUnmounted } from "@vue/composition-api";
import { ENABLED_APPS_GETTERS } from "@/store/enabled-apps/enabled-apps-store-constants";
import useAppConfig from "@core/app-config/useAppConfig";
import {
  myselfMenuItems,
  memberSettingsItems,
} from "@/views/myself/components/myMenu";
import { menuItems } from '@/backoffice/constants/backofficeMenu';
import GridStatusMixin from "@core/mixins/apps-layout/GridStatusMixin";
import { randomString } from "@/@core/utils/utils.js";
import fetchAppsInMenu from "@core/layouts/appsInMenu";
import mixinVerticalLayout from "./mixinVerticalLayout";
import useVerticalLayout from "./useVerticalLayout";
import { checkPermissionsForStaffs } from '@/@core/utils/roles-utils';

export default {
  name: 'LayoutVerticalCore',

  components: {
    AppBreadcrumb: () => import("@core/layouts/components/AppBreadcrumb.vue" /* webpackChunkName: "AppBreadcrumb" */),
    AppFooter: () => import("@core/layouts/components/AppFooter.vue" /* webpackChunkName: "AppFooter" */),
    VerticalNavMenu: () => import('./components/vertical-nav-menu/VerticalNavMenu.vue' /* webpackChunkName: "VerticalNavMenu" */),
    VerticalNavMenuNexos: () => import('./components/vertical-nav-menu/VerticalNavMenuNexos.vue' /* webpackChunkName: "VerticalNavMenuNexos" */),
    LayoutContentRendererDefault: () => import('@core/layouts/components/layout-content-renderer/LayoutContentRendererDefault.vue' /* webpackChunkName: "LayoutContentRendererDefault" */),
    LayoutContentRendererLeft: () => import('@core/layouts/components/layout-content-renderer/LayoutContentRendererLeft.vue' /* webpackChunkName: "LayoutContentRendererLeft" */),
    LayoutContentRendererLeftDetached: () => import('@core/layouts/components/layout-content-renderer/LayoutContentRendererLeftDetached.vue' /* webpackChunkName: "LayoutContentRendererLeftDetached" */),
    AppNavbarVerticalLayout: () => import('@core/layouts/components/app-navbar/AppNavbarVerticalLayout.vue' /* webpackChunkName: "AppNavbarVerticalLayout" */),
    Navbar: () => import('@/layouts/components/Navbar.vue' /* webpackChunkName: "Navbar" */),
  },
  mixins: [mixinVerticalLayout, GridStatusMixin],
  data() {
    return {
      appsInMenu: [],
      membersSettingsInMenu: [],
      key: null,
      // keyForMenus: null,
    };
  },
  computed: {
    onboardingTitle() {
    return this.$t('onboarding.title');
  },
    dataManagerTitle() {
    return this.$t("backoffice.dashboard.title"); 
  },
    hasMain() {
      return (
        this.$store.getters.mainCollective?.slug ||
        this.currentCollective.parentKey !== null
      );
    },
    isMain() {
      return this.currentCollective.parentKey === null;
    },
    isStaff() {
      return this.$store.getters.currentCollective.isStaff;
    },
    isMobile() {
      return this.isGridDisabled;
    },
    isTablet() {
      return window.innerWidth > 1000 && window.innerWidth < 1200;
    },
    hasStructure() {
      return (
        this.currentCollective.communityMain &&
        this.currentCollective.parentKey &&
        this.currentCollective.communityMain !==
          this.currentCollective.parentKey
      );
    },
    isSection() {
      return this.currentCollective.isSection;
    },
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    mainCollective() {
      return this.$store.getters.mainCollective;
    },
    mainSpace() {
      return this.mainCollective.key ? this.mainCollective : this.currentCollective;
    },
    isNexos() {
      return process.env.VUE_APP_CUSTOMER === 'nexos' || this.mainSpace?.key === 'f3166db0-b635-11ef-805d-f79ce25412de';
    },
    layoutContentRenderer() {
      const rendererType = this.$route.meta.contentRenderer;
      if (rendererType === "sidebar-left") {
        return "layout-content-renderer-left";
      }
      if (rendererType === "sidebar-left-detached") {
        return "layout-content-renderer-left-detached";
      }
      return "layout-content-renderer-default";
    },
    layoutContentRendererKey() {
      return this.layoutContentRenderer === "layout-content-renderer-left"
        ? this.$route.meta.navActiveLink || this.$route.name
        : null;
    },
    layoutContentRendererProps() {
      const { fitScreen } = this.$route.meta;
      if (this.layoutContentRenderer !== "layout-content-renderer-default") {
        throw new Error("fitScreen only supported for default renderer");
      }
      const props = {};
      if (fitScreen) {
        props.fitScreen = true;
      }
      return props;
    },
    enabledApps() {
      return this.$store.getters[ENABLED_APPS_GETTERS.enabledApps];
    },
    landingURL() {
      return process.env.VUE_APP_LANDING_URL;
    },
    apps() {
      return this.$store.getters.apps;
    },
    customizations() {
      return this.$store.getters.communityCustomizations?.menu_my_account;
    },
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    isCoordinationCenter() {
      return this.$route.params.communityId.includes("coordination-center");
    },
    hasNotMenu() {
      return (
        this.$route.name === "welcome" ||
        this.$route.name === "globalMemberSettings" ||
        this.$route.name === "myRoles" ||
        this.$route.name === "myConnections" ||
        this.$route.name === "myself-subcommunities" ||
        this.$route.name === "myself-subscription" ||
        this.$route.name === "calendar" ||
        this.$route.name === "email" ||
        this.$route.name === "chats" ||
        this.$route.name === "orders" ||
        this.$route.name === "onboarding-form" ||
        this.$route.name === "onboarding-plans" ||
        this.$route.name === "onboarding-checkout" ||
        this.$route.name === "onboarding-success" ||
        this.$route.name === "developers-center" ||
        this.$route.name === "my-payments"
      );
    },
    hasFooter() {
      return !this.isNexos;
    },
    hasBackofficeMenu() {
      return menuItems.includes(this.$route.name);
    },
    hasMyselfMenu() {
      return myselfMenuItems.includes(this.$route.name);
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    boItems() {
      const items = [];

      // console.log('Is this a main space?', this.isMain);
      // console.log('loggedMemberRoles', this.loggedMemberRoles);

      // Dashboard
      if(this.canManage('dashboard')){
        const boDashboard = 
          {
            title: this.dataManagerTitle,
            route: "backoffice",
            icon: "MonitorIcon",
          };
        items.push(boDashboard);
      }

      // Settings      
      if(this.canManage('settings')){
        const boSettings = 
          {
            header: this.$t("backoffice-menu.config"),
            icon: "SettingsIcon",
            children: [],
          };

        if(this.canManage('general-settings')){
          boSettings.children.push({
            title: this.$t("backoffice-menu.general-settings"),
            route: "settings",
            icon: " ",
          });
        }

        if(this.canManage('general-settings')){
          boSettings.children.push({
            title: this.$t("backoffice-menu.contact-info"),
            route: "bo-contact",
            icon: " ",
          });
        }

        // if(this.canManage('discounts')){
        //   boSettings.children.push({
        //     title: this.$t("backoffice.discounts.title"),
        //     route: "discounts-bo",
        //     icon: " ",
        //   });
        // }

        if(this.canManage('templates')){
          boSettings.children.push({
            title: this.$t("backoffice.templates.title"),
            route: "templates-bo",
            icon: " ",
          });
        }

        if(this.isMain && this.canManage('legal')){
          boSettings.children.push({
            title: this.$t("backoffice.legal.title"),
            route: "backoffice-legal",
            icon: " ",
          });
        }

        items.push(boSettings);
      }

      // Onboarding
      if(this.canManage('onboarding')){
        const boOnboarding = 
          {
            header: this.onboardingTitle,
            icon: "CheckSquareIcon",
            children: [],
          };

        if(this.canManage('onboarding-form')){
          boOnboarding.children.push({
            title: this.$t("backoffice.onboarding-form.subtitle"),
            route: "backoffice-onboarding-form",
            icon: " ",
          });
        }

        if(this.canManage('plans')){
          boOnboarding.children.push({
            title: this.$t("backoffice.subscriptions.subtitle"),
            route: "plans",
            icon: " ",
          });
        }

        items.push(boOnboarding);
      }

      // App Store
      if(this.canManage('apps')){
        const boApps = 
          { 
            title: this.$t("app-store.title"), 
            route: "appstore", 
            icon: "GridIcon" 
          };

        items.push(boApps);
      }

      // Directory
      if(this.canManage('directory')){
        const boDirectory = 
          {
            header: this.$t("tooltips.directory"),
            icon: "UsersIcon",
            children: [],
          };

        if(this.canManage('members')){
          boDirectory.children.push({
            title: this.$t("members.page-title.members-list"),
            route: "backoffice-members",
            icon: " ",
          });
        }

        if(this.isMain && this.canManage('organizations')){
          boDirectory.children.push({
            title: this.$t("backoffice-menu.organizations"),
            route: "backoffice-organizations",
            icon: " ",
          });
        }

        if(this.canManage('roles')){
          boDirectory.children.push({
            title: this.$t("backoffice.roles.title"),
            route: "roles",
            icon: " ",
          });
        }

        // if(this.canManage('referrals')){
        //   boDirectory.children.push({
        //     title: this.$t("backoffice.referrals.title"),
        //     route: "backoffice-referrals",
        //     icon: " ",
        //   });
        // }

        items.push(boDirectory);
      }

      // Communication
      if(this.canManage('communication')){
        const boCommunication = 
          {
            header: this.$t("backoffice.communication.title"),
            icon: "MessageSquareIcon",
            children: [],
          };

        if(this.isMain && this.canManage('feedback')){
          boCommunication.children.push({
            title: this.$t("backoffice.feedback.title"),
            route: "feedback",
            icon: " ",
          });
        }

        if(this.canManage('forms')){
          boCommunication.children.push({
            title: this.$t("backoffice.communication.forms.title"),
            route: "forms-bo",
            icon: " ",
          });
        }

        items.push(boCommunication);
      }

      // Filters
      if(this.canManage('filters')){
        const boFilters = 
          {
            header: this.$t("backoffice-menu.filters"),
            icon: "FilterIcon",
            children: [],
          };

        if(this.canManage('types')){
          boFilters.children.push({
            title: this.$t("backoffice.types.title"),
            route: "types",
            icon: " ",
          });
        }

        if(this.canManage('statuses')){
          boFilters.children.push({
            title: this.$t("backoffice.statuses.title"),
            route: "statuses",
            icon: " ",
          });
        }

        if(this.canManage('classifiers')){
          boFilters.children.push({
            title: this.$t("backoffice.classifiers.name"),
            route: "classifiers",
            icon: " ",
          });
        }

        if(this.canManage('custom-fields')){
          boFilters.children.push({
            title: this.$t("backoffice.custom-fields.name"),
            route: "custom-fields",
            icon: " ",
          });
        }

        items.push(boFilters);
      }
        // {
        //   header: this.$t("backoffice.analytics.title"),
        //   icon: "ActivityIcon",
        //   children: [
        //     {
        //       title: "General",
        //       route: "analytics",
        //       icon: " ",
        //     },
        //     {
        //       title: this.$t("backoffice.analytics.history.title"),
        //       route: "history",
        //       icon: " ",
        //     },
        //   ],
        // },
      
      // Analytics
      // if(this.canManage('analytics')){
      //   const boAnalytics = 
      //     { 
      //       title: "Analytics", 
      //       route: "analytics", 
      //       icon: "ActivityIcon" 
      //     };
      //   items.push(boAnalytics);
      // }

      // Data Manager
      if(this.canManage('data-manager')){
        const boDataManager = 
          { 
            title: this.$t('backoffice.data-manager.title'), 
            route: "data-manager", 
            icon: "DatabaseIcon" 
          };
        items.push(boDataManager);
      }

      // Monetization
      if(this.canManage('discounts') || this.canManage('payments') || this.canManage('sepa')){
        const boMonetization = 
          {
            header: this.$t("backoffice.monetization.title"),
            icon: "DollarSignIcon",
            children: [],
          };

        if(this.canManage('discounts')){
          boMonetization.children.push({
            title: this.$t("backoffice.discounts.title"),
            route: "discounts-bo",
            icon: " ",
          });
        }

        if(this.canManage('payments')){
          boMonetization.children.push({
            title: this.$t("backoffice.payments.title"),
            route: "payments",
            icon: " ",
          });
        }

        if(this.canManage('sepa')){
          boMonetization.children.push({
            title: this.$t("backoffice.sepa.menu-title"),
            route: "sepa",
            icon: " ",
          });
        }

        items.push(boMonetization);
      }

      // Transactions
      if(this.canManage('transactions') || this.canManage('shipments')){
        const boTransactions = 
          {
            header: this.$t("backoffice.operations.title"),
            icon: "RepeatIcon",
            children: [],
          };

        // if(this.canManage('invoices')){
        //   boTransactions.children.push({
        //     title: this.$t("backoffice.invoices.title"),
        //     route: "invoices",
        //     icon: " ",
        //   });
        // }

        if(this.canManage('transactions')){
          boTransactions.children.push({
            title: this.$t("backoffice.transactions.title"),
            route: "transactions",
            icon: " ",
          });
        }

        if(this.canManage('shipments')){
          boTransactions.children.push({
            title: this.$t("backoffice.shipments.title"),
            route: "shipments",
            icon: " ",
          });
        }

        items.push(boTransactions);
      }

      // Transactions
      // if(this.canManage('transactions')){
      //   const boTransactions = 
      //     {
      //       title: this.$t("backoffice.payments.title"),
      //       route: "payments",
      //       icon: "DollarSignIcon",
      //     };
      //   items.push(boTransactions);
      // }

        // {
        //   header: this.$t("backoffice-menu.account"),
        //   children: [
        //     { title: this.$t("backoffice.settings.title"), route: "settings", icon: ' ' },
        //   ],
        // },
        // {
        //   title: this.$t("backoffice.dashboard.coordination-center"),
        //   route: "coordination-center",
        //   icon: "CompassIcon",
        // },
        // {
        //   title: this.$t("backoffice.dev-center.title"),
        //   route: "developers-center",
        //   icon: "CodeIcon",
        // },

      // Backoffice internal things
      if(this.canManage('backoffice')){
        const boBackoffice = 
        {
          header: this.$t("backoffice.administration.title"),
          icon: "ShieldIcon",
          children: [],
        };

        if(this.canManage('staff')){
          boBackoffice.children.push({
            title: this.$t("backoffice-menu.staff"),
            route: "staff",
            icon: " ",
          });
        }

        if(this.canManage('backoffice-roles')){
          boBackoffice.children.push({
            title: this.$t("backoffice.roles.title"),
            route: "staff-roles",
            icon: " ",
          });
        }
        items.push(boBackoffice);
      }

      return items;
    },
    backofficeItems() {
      //Section
      if (this.isSection) {
        this.boItems.map((item, index) => {
          if (
            item?.header === this.$t("backoffice-menu.config") &&
            item?.children?.length === 4
          ) {
            item.children.splice(1, 1);
          }
          if (
            item?.header === this.$t("tooltips.directory") &&
            item?.children?.length > 1
          ) {
            item.children.splice(0, 1);
            item.children.splice(1, 1);
          }
        });
        return this.boItems;
      }

      // It is not a main space but it has bypass main
      if (
        this.hasMain &&
        this.currentCollective.bypass === true &&
        !this.isCoordinationCenter
      ) {
        const auth = {
          title: this.$t("auth.title"),
          route: "auth",
          icon: " ",
        };
        this.boItems.map((item) => {
          if (item?.header === "Onboarding" && item?.children?.length < 3) {
            item.children.splice(0, 0, auth);
          }
        });
        return this.boItems;
      }

      // Availability Rules for Meetings
      if (
        (this.currentCollective.isEvent &&
          this.currentCollective.parentKey !== null) ||
        (this.currentCollective.parentKey !== null &&
          !this.isCoordinationCenter)
      ) {
        // const availability = [
        //   {
        //     title: this.$t("backoffice.availability.title"),
        //     route: "availability",
        //     icon: "WatchIcon",
        //   },
        // ];
        // return [...this.boItems, ...availability];
        
        return [...this.boItems];
      }
      
      // It is a main space
      if (!this.hasMain) {
        // const susc = {
        //   title: this.$t("backoffice.subscriptions.manage.title"),
        //   route: "subscriptions",
        //   icon: " ",
        // };
        const auth = {
          title: this.$t("auth.title"),
          route: "auth",
          icon: " ",
        };
        const owner = {
          title: this.$t("backoffice.owner.title"),
          route: "owner",
          icon: " ",
        };
        this.boItems.map((item) => {
          if (
            item?.header === this.$t("backoffice-menu.config") &&
            item?.children?.length < 5
          ) {
            // if (
            //   !Object.values(this.currentCollective?.subscription).includes(
            //     "APPSUMO_PLAN"
            //   )
            // ) {
            //   item.children.splice(1, 0, susc);
            // }
            item.children.splice(2, 0, owner);
          }
          if (item?.header === this.$t("onboarding.title") && item?.children?.length < 3) {
            item.children.splice(0, 0, auth);
          }
        });

        // const availability = [
        //   {
        //     title: this.$t("backoffice.availability.title"),
        //     route: "availability",
        //     icon: "WatchIcon",
        //   },
        // ];
        // return [...this.boItems, ...availability];
        return [...this.boItems];
      }

      // It is a Main Space:
      const menu = [
        {
          title: this.$t("backoffice.dashboard.title"),
          route: "backoffice",
          icon: "MonitorIcon",
        },
        {
          header: this.$t("backoffice-menu.config"),
          icon: "SettingsIcon",
          children: [
            {
              title: this.$t("backoffice-menu.general-settings"),
              route: "settings",
              icon: " ",
            },
            // {
            //   title: this.$t('backoffice.owner.title'),
            //   route: 'owner',
            //   icon: ' ',
            // },
            {
              title: this.$t("backoffice.onboarding-form.title"),
              route: "backoffice-onboarding-form",
              icon: " ",
            },
            {
              title: this.$t("backoffice.templates.title"),
              route: "templates-bo",
              icon: " ",
            },
            {
              title: this.$t("backoffice.legal.title"),
              route: "backoffice-legal",
              icon: " ",
            },
            // {
            //   title: this.$t('backoffice.subscriptions.manage.title'),
            //   route: 'subscriptions',
            //   icon: ' ',
            // },
            // {
            //   title: this.$t('auth.title'),
            //   route: 'auth',
            //   icon: ' ',
            // },
          ],
        },
        { title: "App Store", route: "appstore", icon: "GridIcon" },
        {
          header: this.$t("tooltips.directory"),
          icon: "UsersIcon",
          children: [
            {
              title: this.$t("members.page-title.members-list"),
              route: "backoffice-members",
              icon: " ",
            },
            {
              title: this.$t("backoffice.roles.title"),
              route: "roles",
              icon: " ",
            },
            // {
            //   title: this.$t("backoffice.referrals.title"),
            //   route: "backoffice-referrals",
            //   icon: " ",
            // },
          ],
        },
        {
          header: this.$t("backoffice.communication.title"),
          icon: "MessageSquareIcon",
          children: [
            {
              title: this.$t("backoffice.feedback.title"),
              route: "feedback",
              icon: " ",
            },
            {
              title: this.$t("backoffice.communication.forms.title"),
              route: "forms-bo",
              icon: " ",
            },
          ],
        },
        {
          header: this.$t("backoffice-menu.filters"),
          icon: "FilterIcon",
          children: [
            {
              title: this.$t("backoffice.types.title"),
              route: "types",
              icon: " ",
            },
            {
              title: this.$t("backoffice.statuses.title"),
              route: "statuses",
              icon: " ",
            },
            {
              title: this.$t("backoffice.classifiers.name"),
              route: "classifiers",
              icon: " ",
            },
            {
              title: this.$t("backoffice.custom-fields.name"),
              route: "custom-fields",
              icon: " ",
            },
          ],
        },
        // {
        //   header: this.$t("backoffice.analytics.title"),
        //   icon: "ActivityIcon",
        //   children: [
        //     {
        //       title: "General",
        //       route: "analytics",
        //       icon: " ",
        //     },
        //     {
        //       title: this.$t("backoffice.analytics.history.title"),
        //       route: "history",
        //       icon: " ",
        //     },
        //   ],
        // },
        // {
        //   title: this.$t("backoffice.analytics.title"),
        //   route: "analytics",
        //   icon: "ActivityIcon",
        // },
        {
          title: this.$t("backoffice.payments.title"),
          route: "payments",
          icon: "DollarSignIcon",
        },
        // {
        //   title: this.$t("backoffice.availability.title"),
        //   route: "availability",
        //   icon: "WatchIcon",
        // },
        // {
        //   title: this.$t("backoffice.dashboard.coordination-center"),
        //   route: "coordination-center",
        //   icon: "CompassIcon",
        // },
        // {
        //   title: this.$t("backoffice.dev-center.title"),
        //   route: "developers-center",
        //   icon: "CodeIcon",
        // },
      ];
      
      if (this.isCoordinationCenter) {
        menu.splice(9, 1);
        menu.map((item) => {
          if (item?.header === this.$t("backoffice-menu.config")) {
            item.children.splice(2, 1);
            item.children.splice(2, 1);
            item.children.splice(4, 1);
          }
        });
        menu.splice(6, 1);
      }
      return menu;
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    hasVerticalMenu() {
      return (
        this.isNexos ||
        (!this.hasNotMenu &&
          !this.isNavMenuHidden &&
          this.appsInMenu &&
          this.appsInMenu.length > 1) ||
        this.hasBackofficeMenu ||
        this.hasMyselfMenu
      );
    },
  },
  watch: {
    "$i18n.locale": async function (newVal, oldVal) {
      this.appsInMenu = await fetchAppsInMenu();
      //console.log('this.appsInMenu', this.appsInMenu);
      // this.keyForMenus = randomString();
    },
    async apps() {
      this.appsInMenu = await fetchAppsInMenu();
      //console.log('this.appsInMenu', this.appsInMenu);
      // this.keyForMenus = randomString();
    },
    customizations: {
      immediate: true,
      handler(newValue) {
        this.membersSettingsInMenu = memberSettingsItems(newValue);
        // this.keyForMenus = randomString();
      },
    }
  },
  async mounted() {
    // This call will not be made if we are in my-netios
    this.$router.afterEach((to, from) => {
      const fromPathIndex = from.fullPath.indexOf("?");
      const toPathIndex = to.fullPath.indexOf("?");
      let fromPath =
        fromPathIndex > -1
          ? from.fullPath.substring(0, fromPathIndex)
          : from.fullPath;
      let toPath =
        toPathIndex > -1 ? to.fullPath.substring(0, toPathIndex) : to.fullPath;
      if (fromPath.indexOf("#") > -1) {
        fromPath = fromPath.substring(0, fromPath.indexOf("#"));
      }
      if (toPath.indexOf("#") > -1) {
        toPath = toPath.substring(0, toPath.indexOf("#"));
      }
      if (fromPath !== toPath && from.fullPath != null) {
        this.key = randomString();
      }
    });

    if (this.$route?.params?.communityId) {
      // console.log('HAVE communityId: ', this.$route.params.communityId)
      this.appsInMenu = await fetchAppsInMenu();
      //console.log('this.appsInMenu', this.appsInMenu);
    }
  },
  setup() {
    const {
      routerTransition,
      navbarBackgroundColor,
      navbarType,
      footerType,
      isNavMenuHidden,
    } = useAppConfig();
    const {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      isVerticalMenuCollapsed,
      layoutClasses,
      overlayClasses,
      resizeHandler,
      navbarTypeClass,
      footerTypeClass,
    } = useVerticalLayout(navbarType, footerType);
    // Resize handler
    resizeHandler();
    window.addEventListener("resize", resizeHandler);
    onUnmounted(() => {
      window.removeEventListener("resize", resizeHandler);
    });
    return {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      isVerticalMenuCollapsed,
      overlayClasses,
      layoutClasses,
      navbarTypeClass,
      footerTypeClass,
      // App Config
      routerTransition,
      navbarBackgroundColor,
      isNavMenuHidden,
    };
  },
  methods: {
    canManage(area) {
      const can = checkPermissionsForStaffs(
        'manage',
        area,
        this.loggedMemberRoles,
        this.$store.getters.currentCollective,
      );
      // console.log('can manage '+area+'?', can);
      return can;
    },
  }
};
</script>
<style lang="scss">
@import "~@core/scss/base/themes/bordered-layout.scss";

.noVerticalMenu {
  margin-left: 0px !important;
}

.allMenu {
  padding: 0 10px;
  margin-left: 5px;
  width: calc(100vw - 20px);
  .header-navbar {
    @include media-breakpoint-down(lg) {
      width: calc(100vw - 40px);
      margin-right: 20px;
    }
    @include media-breakpoint-up(xl) {
      width: calc(100vw - 60px);
      margin-right: 30px;
    }
  }
}

.navbar-full-width {
  width: 100% !important;
  z-index: 1040 !important;
  left: 0 !important;
}
</style>
